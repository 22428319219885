import React, { FunctionComponent } from 'react';
import { Contributor, MovieSlideData } from '../../../domain/work';
import { isPerson, getPersonFullName } from '../../../domain/utils/MovieUtils';
import { NonBreakingWhiteSpace } from '../NonBreakingSpace';
import { LocalizedConjunctionFormatter } from '../LocalizedListFormatter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import compact from 'lodash/compact';
import identity from 'lodash/identity';
import { WithT } from 'i18next';

export interface DirectorCreditLineProps extends WithT {
    movie: MovieSlideData;
    directorNameRenderer?: (directorFullName: string) => JSX.Element;
    className?: string;
}

export const DirectorCreditLine: FunctionComponent<DirectorCreditLineProps> = ({
    movie,
    t,
    className,
    directorNameRenderer,
}: DirectorCreditLineProps): JSX.Element | null => {
    const directorsFullNames: string[] = compact(
        map(movie.directors, (contributor: Contributor): string | undefined => {
            return isPerson(contributor) ? getPersonFullName(contributor) : undefined;
        })
    );

    if (isEmpty(directorsFullNames)) {
        return null;
    }

    return (
        <span className={className}>
            {t('common:by')}
            <NonBreakingWhiteSpace />
            <LocalizedConjunctionFormatter items={directorsFullNames} itemRenderer={directorNameRenderer!} t={t} />
        </span>
    );
};

DirectorCreditLine.defaultProps = {
    directorNameRenderer: identity,
};
